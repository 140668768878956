import React from "react";
import { createRoot } from "react-dom/client";
import camelcaseKeys from "camelcase-keys";
import parseDataset from "@/shared/utils/parseDataset";

window.loadComponents = async (rootElement) => {
  const elements = rootElement.querySelectorAll("[data-component]");

  elements.forEach(async element => {
    const filename = element.dataset.component;

    const Component = await import(`../react_frontend/${filename}`);

    const data = camelcaseKeys(parseDataset(element.dataset), { deep: true });
    createRoot(element).render(React.createElement(Component.default, data.props));
  });
};

document.addEventListener("DOMContentLoaded", () => window.loadComponents(document));
